import React, { useState, useEffect } from "react";
import {
  useMoralis,
} from "react-moralis";
import { Modal } from "antd";
import { useMoralisDapp } from "providers/MoralisDappProvider/MoralisDappProvider";
import { useWeb3ExecuteFunction } from "react-moralis";
import { useIPFS } from "hooks/useIPFS";

function MintNow() {
  const { isAuthenticated } = useMoralis();
  const contractProcessor = useWeb3ExecuteFunction();
  const { chainId, tokenContractAbi, tokenContractAndress, nftContractAbi, nftContractAddress } =  useMoralisDapp();
  const { resolveLink } = useIPFS();

  const [loading, setLoading] = useState(false);
  const [totalSupply, setTotalSupply] = useState(6666);
  const [NFTImage, setNFTImage] = useState('');

  const tokenContractABIJson = JSON.parse(tokenContractAbi);
  const nftContractABIJson = JSON.parse(nftContractAbi);

  const aproveSpendFunction = "approve";
  const nftMintFunction = "mint";
  const nftTotalSupplyFunction = "totalSupply";
  const nftTokenURIFunction = "tokenURI";

  useEffect(() => {
    getMintSupply();
  }, [totalSupply]);

  async function approveTokenSpend() {
    setLoading(true);
    const ops = {
      contractAddress: tokenContractAndress,
      functionName: aproveSpendFunction,
      abi: tokenContractABIJson,
      params: {
        spender: nftContractAddress,
        amount: String(500 * ("1e" + 18)),
      },
      //msgValue: tokenPrice,
    };

    await contractProcessor.fetch({
      params: ops,
      onSuccess: () => {
        console.log("success");
        mintToken();
      },
      onError: (error) => {
        console.log(error);
        failPurchase();
      },
    });
  }

  async function mintToken() {
    const ops = {
      contractAddress: nftContractAddress,
      functionName: nftMintFunction,
      abi: nftContractABIJson,
      params: {
        _mintAmount: 1
      },
      msgValue: '100000000000000000',
    };

    await contractProcessor.fetch({
      params: ops,
      onSuccess: (data) => {
        //setCurrentToken();
        getMintedTokenJson(data.events.Transfer.returnValues['tokenId']);
        succPurchase();
      },
      onError: (error) => {
        failPurchase();
      },
    });
  }

  async function getMintedTokenJson(tokenID) {
    console.log(tokenID);
    const ops = {
      contractAddress: nftContractAddress,
      functionName: nftTokenURIFunction,
      abi: nftContractABIJson,
      params: {
        _tokenId: tokenID
      }
    };

    await contractProcessor.fetch({
      params: ops,
      onSuccess: (responseURI) => {
        console.log(responseURI);
        
        try {
          fetch(resolveLink(responseURI))
            .then((response) => response.json())
            .then((data) => {
              console.log(resolveLink(data.image));
              setNFTImage(resolveLink(data.image));
            });
        } catch (error) {
          console.log(error);
        }

      },
      onError: (error) => {
        console.log(error);
      },
    });
  }

  function getMintSupply() {
    const ops = {
      contractAddress: nftContractAddress,
      functionName: nftTotalSupplyFunction,
      abi: nftContractABIJson
    };

    contractProcessor.fetch({
      params: ops,
      onSuccess: (data) => {
        setTotalSupply(parseInt(6666 - data));
       
      },
      onError: (error) => {
        console.log(error);
      },
    });
  }

  //getMintSupply();

  function succPurchase() {
    let secondsToGo = 5;
    setLoading(false);
    const modal = Modal.success({
      title: "Success!",
      content: `You have purchased this NFT`,
    });
    setTimeout(() => {
      modal.destroy();
    }, secondsToGo * 1000);
  }

  function failPurchase() {
    let secondsToGo = 5;
    setLoading(false);
    const modal = Modal.error({
      title: "Error!",
      content: `There was a problem when purchasing this NFT`,
    });
    setTimeout(() => {
      modal.destroy();
    }, secondsToGo * 1000);
  }

  if (!isAuthenticated) {
    return(
      <div className="content minting">
          <div className="headline full center"><img src={`${process.env.PUBLIC_URL}images/mint_header.png`}  alt="Hardrock Titans NFT Collection" /></div>
          <div className="minting_content full center">
            <div className="full center">Please log in to mint!</div>
          </div>
			</div>
    
    
    );
  } else if(chainId !== '0x38') {
    return(
     
      <div className="content minting">
          <div className="headline full center"><img src={`${process.env.PUBLIC_URL}images/mint_header.png`}  alt="Hardrock Titans NFT Collection" /></div>
          <div className="minting_content full center">
            <div className="full center">Please switch to the BSC network</div>
          </div>
			</div>
      );
  }
  else {
    return (
        <>
        <div className="content minting">
          <div className="headline full center"><img src={`${process.env.PUBLIC_URL}images/mint_header.png`}  alt="Hardrock Titans NFT Collection" /></div>
          <div className="minting_content full center">
            <div className="minting_frame">
              <div className="mint_supply">
                NFT Mint Supply<br />{totalSupply}
              </div>
              <div className="mint_cost">
                Minting<br />500 TST &amp; 0.1 BNB
              </div>
            </div>
            <div className="minting_image">
            {NFTImage==='' && loading===false &&
              <img src={`${process.env.PUBLIC_URL}images/mintgif.gif`} width="250" height="250" alt="Titans" />
            }
            {NFTImage==='' && loading===true &&
              <img src={`${process.env.PUBLIC_URL}images/mintgif_faster.gif`} width="250" height="250" alt="Titans" />
            }
            {NFTImage!=='' &&
              <img src={NFTImage} width="250" height="250" alt="Titans" />
            }
            </div>
            
            { loading
              ?<div className="minting_info full center">There will be 2 transactions. <br />One for TST Token approval and one for minting.<br /> <br /> Transaction is being processed. <br />Please do not leave or close this window until complete</div>
              :<div className="minting_button"><a className="mint_button" onClick={approveTokenSpend}>Mint</a></div>
            }
            <div className="minting_band full center">
              <img src={`${process.env.PUBLIC_URL}images/band.png`} alt="hardrock titans" />
            </div>
          </div>
			</div>
       </>
    );
  }
}

export default MintNow;
