import {
  useMoralis,
} from "react-moralis";
import { useNFTTokenIds } from "hooks/useNFTTokenIds";
import { useMoralisDapp } from "providers/MoralisDappProvider/MoralisDappProvider";

function NFTTokenIds() {
  const { isAuthenticated } = useMoralis();
  const { NFTTokenIds } = useNFTTokenIds();
  const { chainId } = useMoralisDapp();

 
  if (!isAuthenticated) {
    return(
      <>
        <div className="content collection">
          <div className="headline full center"><img src={`${process.env.PUBLIC_URL}images/collection_header.png`} alt="Hardrock Titans NFT Collection" /></div>
          <div className="collection_list full">
            <div className="full center">Please log in to view your collection</div>
          </div>
        </div>
      </>
    );
  } else if(chainId!=='0x38') {
    return(
      
      <>
        <div className="content collection">
          <div className="headline full center"><img src={`${process.env.PUBLIC_URL}images/collection_header.png`} alt="Hardrock Titans NFT Collection" /></div>
          <div className="collection_list full">
            <div className="full center">Please switch to the BSC network</div>
          </div>
        </div>
      </>
    );
  }
  else {
    return (
      <>
        <div className="content collection">
          <div className="headline full center"><img src={`${process.env.PUBLIC_URL}images/collection_header.png`} alt="Hardrock Titans NFT Collection" /></div>
          <div className="collection_list full">
            {NFTTokenIds.slice(0, 21).map((nft, index) => (
                <div className="nft_entry" key={index}>
                  <img src={`${process.env.PUBLIC_URL}images/nfts/${nft.imagepath}`} alt={nft.name} />
                  <div className="nft_count">x{nft.quantity}</div>
                </div>
              ))}
          </div>
        </div>
      </>
    );
  }
}

export default NFTTokenIds;
