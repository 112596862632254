import { useMoralisDapp } from "providers/MoralisDappProvider/MoralisDappProvider";
import { useEffect, useState } from "react";
import { useMoralisWeb3Api, useMoralisWeb3ApiCall } from "react-moralis";

export const useNFTTokenIds = () => {
  const { account } = useMoralisWeb3Api();
  const { chainId, nftContractAddress } = useMoralisDapp();
  const [NFTTokenIds, setNFTTokenIds] = useState([]);
  const [totalNFTs, setTotalNFTs] = useState(0);
  const [collectionSize, setCollectionSize] = useState(0);
  const [fetchSuccess, setFetchSuccess] = useState(true);
  const {
    fetch: getNFTTokenIds,
    data,
    error,
    isLoading,
  } = useMoralisWeb3ApiCall(account.getNFTsForContract, {
    chain: chainId,
    token_address: nftContractAddress,
    limit: 10,
  });

  let originalList = [ 
    { name: "Jimmy Hendrix", quantity: 0, imagepath: 'hendrix.png'},
    { name: "Brian Johnson", quantity: 0, imagepath: 'johnson.png'},
    { name: "ACDC Band", quantity: 0, imagepath: 'band.png'},
    { name: "Angus Young", quantity: 0, imagepath: 'young.png'},
    { name: "Robert Plant", quantity: 0, imagepath: 'plant.png'},
    { name: "Ozzy Osbourne", quantity: 0, imagepath: 'osbourne.png'},
    { name: "Mick Jagger", quantity: 0, imagepath: 'jagger.png'},
    { name: "Roger Waters", quantity: 0, imagepath: 'waters.png'},
    { name: "Freddy Mercury", quantity: 0, imagepath: 'mercury.png'},
    { name: "Van Halen", quantity: 0, imagepath: 'halen.png'},
    { name: "Carlos Santana", quantity: 0, imagepath: 'santana.png'},
    { name: "David Coverdale", quantity: 0, imagepath: 'coverdale.png'},
    { name: "Roger Daltrey", quantity: 0, imagepath: 'daltrey.png'},
    { name: "John Lennon", quantity: 0, imagepath: 'lennon.png'},
    { name: "Stevie Nicks", quantity: 0, imagepath: 'nicks.png'},
    { name: "Eric Clapton", quantity: 0, imagepath: 'clapton.png'},
    { name: "Suzie Quatro", quantity: 0, imagepath: 'quatro.png'},
    { name: "Alice Cooper", quantity: 0, imagepath: 'cooper.png'},
    { name: "Billy Gibbons", quantity: 0, imagepath: 'gibbons.png'},
    { name: "Ronnie Van Zant", quantity: 0, imagepath: 'zant.png'},
    { name: "Don Felder", quantity: 0, imagepath: 'felder.png'}
];
  useEffect(async () => {
    if (data?.result) {
      const NFTs = data.result;
      let nftCount = 0;
      setFetchSuccess(true);
      for (let NFT of NFTs) {
        if (NFT?.metadata) {
          NFT.metadata = JSON.parse(NFT.metadata);
          NFT.name = NFT.metadata.attributes[0].value;

          originalList.find((o, i) => {
              if (o.name === NFT.name) {
                if(originalList[i].quantity===0) nftCount++;
                originalList[i].quantity++;
                return true; // stop searching
              }
          });
        } else if (NFT?.token_uri) {
          try {
            await fetch(NFT.token_uri)
              .then((response) => response.json())
              .then((data) => {
                NFT.metadata = data;
                NFT.name = data.attributes[0].value;

                originalList.find((o, i) => {
                  if (o.name === NFT.name) {
                    if(originalList[i].quantity===0) nftCount++;
                    originalList[i].quantity++;
                    return true; // stop searching
                  }
                });
              });
          } catch (error) {
            setFetchSuccess(false);
          }
        }
      }

      for (let item of originalList) {
        if(item.quantity == 0)
        {
          let newPath = item.imagepath.replace('.png', '_bw.png');
          item.imagepath = newPath;
        }
      }

      setCollectionSize(originalList.length);
      setTotalNFTs(nftCount);
      setNFTTokenIds(originalList);
    }
  }, [data]);

  return {
    getNFTTokenIds,
    NFTTokenIds,
    totalNFTs,
    collectionSize,
    fetchSuccess,
    error,
    isLoading,
  };
};
