import { useEffect, useState} from "react";
import { useMoralis } from "react-moralis";
import {
  BrowserRouter as Router,
  HashRouter,
  Switch,
  Route,
  NavLink,
  Redirect,
} from "react-router-dom";
import Account from "components/Account";
import NFTTokenIds from "components/NFTTokenIds";
import Information from "components/Information";
import { Menu, Layout} from "antd";
import "antd/dist/antd.css";
import "./style.css";
import MintNow from "components/MintNow";
import PrizePools from "components/PrizePools";
import ComingSoon from "components/ComingSoon";

const App = ({ isServerInfo }) => {
  const { isWeb3Enabled, enableWeb3, isAuthenticated, isWeb3EnableLoading } =
    useMoralis();

  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading) enableWeb3();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isWeb3Enabled]);

  function openMenu() {
    if(showMenu)
    {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
    
  }
  function closeMenu() {
    setShowMenu(false);
  }
  return (
    <Layout className="main_wrapper" >
      <HashRouter>
        <a onClick={openMenu} className="mobile_menu_burger">Menu</a>
        <div className="mobile_logo full center"><a><img src={`${process.env.PUBLIC_URL}images/logo.png`} alt="logo" /></a></div>
      
      {showMenu &&
        <Menu 
           className="mobile_menu"
            defaultSelectedKeys={["information"]}
          >
            <Menu.Item onClick={ closeMenu } key="information">
              <NavLink to="/information">Information</NavLink>
            </Menu.Item>
            <Menu.Item onClick={ closeMenu } key="minting">
              <NavLink to="/minting">Minting</NavLink>
            </Menu.Item>
            <Menu.Item onClick={ closeMenu } key="HardrockTitans" >
              <NavLink to="/HardrockTitans">Hardrock Titans</NavLink>
            </Menu.Item>
            <Menu.Item onClick={ closeMenu } key="MetalGods" >
              <NavLink to="/MetalGods">Metal Gods</NavLink>
            </Menu.Item>
            <Menu.Item onClick={ closeMenu } key="PunkDegenerates" >
              <NavLink to="/PunkDegenerates">Punk Degenerates</NavLink>
            </Menu.Item>
            <Menu.Item onClick={ closeMenu } key="AlternativeJarheads" >
              <NavLink to="/AlternativeJarheads">Alternative Jarheads</NavLink>
            </Menu.Item>
            <Menu.Item onClick={ closeMenu } key="prizepools" >
              <NavLink to="/prizepools">Prize Pools</NavLink>
            </Menu.Item>
            <div className="socials center full">
              <ul>
                <li><a href="https://t.me/thunderstruck_token" target="_blank"><img src={`${process.env.PUBLIC_URL}images/email_icon.png`}  alt="email" /></a></li>
                <li><a href="https://t.me/thunderstruck_token" target="_blank"><img src={`${process.env.PUBLIC_URL}images/telegram_icon.png`}  alt="telegram" /></a></li>
                <li><a href="https://twitter.com/TST_Coin" target="_blank"><img src={`${process.env.PUBLIC_URL}images/twitter_icon.png`}  alt="twitter" /></a></li>
              </ul>
            </div>
          </Menu>
      }
        <div className="sidebar">
          <div className="logo full center"><a><img src={`${process.env.PUBLIC_URL}images/logo.png`} alt="logo" /></a></div>
          <Menu
           className="menu full center"
            defaultSelectedKeys={["information"]}
          >
            <Menu.Item key="information">
              <NavLink to="/information">Information</NavLink>
            </Menu.Item>
            <Menu.Item key="minting">
              <NavLink to="/minting">Minting</NavLink>
            </Menu.Item>
            <Menu.Item key="HardrockTitans" >
              <NavLink to="/HardrockTitans">Hardrock Titans</NavLink>
            </Menu.Item>
            <Menu.Item key="MetalGods" >
              <NavLink to="/MetalGods">Metal Gods</NavLink>
            </Menu.Item>
            <Menu.Item key="PunkDegenerates" >
              <NavLink to="/PunkDegenerates">Punk Degenerates</NavLink>
            </Menu.Item>
            <Menu.Item key="AlternativeJarheads" >
              <NavLink to="/AlternativeJarheads">Alternative Jarheads</NavLink>
            </Menu.Item>
            <Menu.Item key="prizepools" >
              <NavLink to="/prizepools">Prize Pools</NavLink>
            </Menu.Item>
          </Menu>

          <div className="socials center full">
            <ul>
              <li><a href="https://t.me/thunderstruck_token" target="_blank"><img src={`${process.env.PUBLIC_URL}images/email_icon.png`}  alt="email" /></a></li>
              <li><a href="https://t.me/thunderstruck_token" target="_blank"><img src={`${process.env.PUBLIC_URL}images/telegram_icon.png`}  alt="telegram" /></a></li>
              <li><a href="https://twitter.com/TST_Coin" target="_blank"><img src={`${process.env.PUBLIC_URL}images/twitter_icon.png`}  alt="twitter" /></a></li>
            </ul>
          </div>

        </div>

        <div className="right_side">

          <div className="top right">
            <Account />
          </div>
       
            <Switch>
              <Route path="/MetalGods">
                <ComingSoon/>
              </Route>
              <Route path="/PunkDegenerates">
                <ComingSoon/>
              </Route>
              <Route path="/AlternativeJarheads">
                <ComingSoon/>
              </Route>
              <Route path="/HardrockTitans">
                <NFTTokenIds/>
              </Route>
              <Route path="/minting">
                <MintNow />
              </Route>
              <Route path="/information">
                <Information />
              </Route>
              <Route path="/prizepools">
                <PrizePools />
              </Route>
            </Switch>
            <Redirect to="/information" />
        </div>
      </HashRouter>

    </Layout>
  );
};

export const Logo = () => (
  <div style={{ display: "flex" }}>
    TST

  </div>
);

export default App;
