import { Link } from "react-router-dom";

function ComingSoon() {
  return (
    <>
      <div className="content comingsoon full">
				<div className="text_holder full">
					<p>
						This collection is not yet released. 
					</p>
					<p>
						<strong>Meanwhile, checkout the Hardrock Titans Collection!</strong>
					</p>
				</div>
				<div className="mint_redirect full center">
					<Link to="/minting" className="mint_button">Mint</Link>
				</div>
			</div>
    </>
  );
}

export default ComingSoon;
