function PrizePools() {
  return (
    <>
      <div className="content prizelist full">
        <div className="prize_list full">
					
            <div className="prize_collection full">
                <div className="icon"><img src={`${process.env.PUBLIC_URL}images/prize_icon_titans.png`} alt="Titans" /></div>

                <div className="collection_details">
                    
                    <ul>
                        <li>Hardrock Titans<br />Collection</li>
                        <li>BNB Prize pool<br />Up to: 666.6 BNB</li>
                        <li>TST Prize pool<br />Up to: 3333000 TST</li>
                        <li>Minting will end on<br />03/06/2022</li>
                    </ul>
                </div>

            </div>
            
            <div className="prize_collection full">
                <div className="icon"><img src={`${process.env.PUBLIC_URL}images/prize_icon_tba.png`} alt="Titans" /></div>
                <div className="collection_details">
                    
                    <ul>
                        <li>Metal Gods<br />Collection</li>
                        <li>BNB Prize pool<br />N/A BNB</li>
                        <li>TST Prize pool<br />N/A TST</li>
                        <li>Minting will end on<br />dd/mm/yyyy</li>
                    </ul>
                </div>
            </div>
            
            <div className="prize_collection full">
                <div className="icon"><img src={`${process.env.PUBLIC_URL}images/prize_icon_tba.png`} alt="Titans" /></div>
                <div className="collection_details">
                    
                    <ul>
                        <li>Punk Degenerates<br />Collection</li>
                        <li>BNB Prize pool<br />N/A BNB</li>
                        <li>TST Prize pool<br />N/A TST</li>
                        <li>Minting will end on<br />dd/mm/yyyy</li>
                    </ul>
                </div>
            </div>
            
            <div className="prize_collection full">
                <div className="icon"><img src={`${process.env.PUBLIC_URL}images/prize_icon_tba.png`} alt="Titans" /></div>
                <div className="collection_details">
                    <ul>
                        <li>Alternative Jarheads<br />Collection</li>
                        <li>BNB Prize pool<br />N/A BNB</li>
                        <li>TST Prize pool<br />N/A TST</li>
                        <li>Minting will end on<br />dd/mm/yyyy</li>
                    </ul>
                </div>
            </div>
            
            <div className="prize_collection full">
                <div className="icon"><img src={`${process.env.PUBLIC_URL}images/prize_icon_multiple.png`} alt="Titans" /></div>
                <div className="collection_details">
                    <ul>
                        <li>Multiple Collection<br />Holder Prize Pool</li>
                        <li>BNB Prize pool<br />N/A BNB</li>
                        <li>TST Prize pool<br />N/A TST</li>
                        <li>Minting will end on<br />dd/mm/yyyy</li>
                    </ul>
                </div>
            </div>
            
        </div>

	    </div>
    </>
  );
}

export default PrizePools;
