import { Link } from "react-router-dom";

function Information() {
  return (
    <>
      <div className="content information full">
				<div className="heading full center"><img src={`${process.env.PUBLIC_URL}images/information_headline.png`} alt="Thunderstruck Token NFT Collections" /></div>
				<div className="text_holder full">
					<p>
						Our goal is to offer these great bands and artist a tribute through all that the crypto world has to offer. Our first step towards this is launching a series of NFT collections: Hard Rock Titans, Metal Gods, Punk Rock Degenerates, Alternative Rock Jarheads and a few more (still thinking of a cool name for them). These will spread the names of these artists through the Crypto community in the hope that names like Bon Scott, Ozzie Osbourne, Jimmy Hendrix, Van Halen etc. will not be forgotten in the vastness of time.
					</p>
					<p>
						<strong>Collect them all, earn your prize and buy some of their music! LET THERE BE ROCK!</strong>
					</p>
					<p>
						In order to be able to mint any NFT from our collection, all users will need to preapprove the mint in TST first. In order to do this, you would have to own at least 501 TST. Bellow the process explained through a diagram:
					</p>
				</div>
				<div className="mint_instructions center full">
					<ul>
						<li>Buy TST via<br/>pancake swap</li>
						<li>Approve<br />Minting</li>
						<li>MINT NFT</li>
					</ul>
					<div className="instruction_arrows"><img src={`${process.env.PUBLIC_URL}images/steps_arrows.png`} alt="arrows" /></div>
				</div>
				<div className="mint_redirect full center">
					<Link to="/minting" className="mint_button">Mint</Link>
				</div>
			</div>
    </>
  );
}

export default Information;
